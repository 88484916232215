import { Component } from '@angular/core';

/**
 * The offline component for the public services portal.
 */
@Component({
  selector: 'bas-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent {
}
