import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InitComponent } from 'app/init.component';
import { OfflineComponent } from 'app/offline.component';

const routes: Routes = [
  { path: '', redirectTo: '/init', pathMatch: 'full' },
  { path: 'init', component: InitComponent },
  {
    path: 'offline',
    component: OfflineComponent,
    data: {
      standalone: true
    }
  },
  {
    path: 'status-certificates',
    loadChildren: () => import('app/features/status-certificates/status-certificates.module')
      .then(m => m.StatusCertificatesModule)
  },
  { path: '**', redirectTo: '/init' },
];

/**
 * App routing module for the community portal.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
