import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { StateService } from 'app/core/services/state.service';


/**
 * Service used for starting up the application.
 */
@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(
    private stateService: StateService,
    private translateService: TranslateService) {
  }

  /**
   * Sets up the initial application state.
   */
  public setup(): Promise<any> {
    const promise = new Promise(resolve => {
      this.translateService.setDefaultLang('en');
      resolve({});
    })
      .then(async () => {
        await this.stateService.updateLocale();
      })
      .catch(err => console.log(err));

    return promise;
  }
}
