// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .container {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
:host .image-container img {
  width: auto;
  height: 100%;
}
:host .router-container {
  flex: auto;
  flex-flow: column;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,cAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AAAJ;AAII;EACE,WAAA;EACA,YAAA;AAFN;AAME;EACE,UAAA;EACA,iBAAA;AAJJ","sourcesContent":[":host {\n  .container {\n    display: flex;\n    flex-flow: row;\n    width: 100%;\n    height: 100vh;\n    overflow: hidden;\n  }\n\n  .image-container {\n    img {\n      width: auto;\n      height: 100%;\n    }\n  }\n\n  .router-container {\n    flex: auto;\n    flex-flow: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
