import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { map, Observable } from 'rxjs';

import { StatusCertificateStatusEnum } from 'app/core/enums/status-certificate-status.enum';
import { StatusCertificateService } from 'app/core/services/api/status-certificate.service';
import { StateService } from 'app/core/services/state.service';

/**
 * Router guard that checks if the payment status is unpaid before allowing payment to proceed.
 */
@Injectable()
export class StatusCertificatePaymentGuard {
  constructor(private _router: Router,
    private _statusCertificateService: StatusCertificateService,
    private _stateService: StateService,
    private _destroyRef: DestroyRef) {
  }
  /**
   * Determines if the user can activate the payment route based on the status of the status certificate.
   *
   * @param route
   * @param state
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this._statusCertificateService.getStatusCertificatePaymentDetails()
      .pipe(
        map(resp => {

          // If the status certificate is already paid, redirect to the certificate status page
          if (resp.status !== StatusCertificateStatusEnum.Unpaid) {
            // need to pass the client token to the status page so reloading works
            this._router
              .navigate(['/status-certificates/status'], {
                state: { paymentDetails: resp },
                queryParams: { clientToken: this._stateService.clientToken }
              })
              .then(() => false);
            return false;
          }

          route.params = { paymentDetails: resp };
          return true;
        }),
        takeUntilDestroyed(this._destroyRef)
      );
  }
}
