// References:
// https://github.com/Caballerog/angular-ngx-translate-typing
// https://medium.com/swlh/angular-ngx-translate-typings-f29e3049f726

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as en from 'assets/i18n/en';
import * as _ from 'lodash-es';

import { toTranslationKeys } from 'app/core/helpers/to-translation-keys';

/**
 * Generic class to add properties through Typescript Magic™
 */
export function GenericClass<Props>(): new () => Props {
  return class {} as any;
}

const deepValues = (o) => {
  if (Array.isArray(o)) {
    return _.flatMap(o, i => typeof i === 'string' ? i : deepValues(Object.values(i)));
  } else {
    return deepValues(Object.values(o));
  }
};

const deepReduce = (translations, translated) => Object.entries(translations)
  .reduce((acc, [key, value]) => {
    if (typeof value === 'string') {
      acc[key] = translated[value];
    } else {
      acc[key] = deepReduce(translations[key], translated);
    }

    return acc;
  }, {});

/**
 * Represent the available translation keys.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationsService extends GenericClass<typeof en.translations>() {

  constructor(private translateService: TranslateService) {
    super();
    Object.assign(this, toTranslationKeys(en.translations));
  }

  /**
   * Bulk translate translations in a type-safe manner.
   *
   * @param translations The translations to translate.
   */
  public bulk<T>(translations: T): T {
    const keys = deepValues(translations);

    const translated = this.translateService.instant(keys);

    const result = deepReduce(translations, translated) as T;

    return result;
  }
}
