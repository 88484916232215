import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { SystemAlertModel } from 'app/core/models/ui/system-alert.model';

/**
 * Service that manages the layout of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private alertNotificationSource = new Subject<SystemAlertModel>();

  /**
   * Observable that returns an alert of type notification
   */
  public alertNotification$ = this.alertNotificationSource.asObservable();

  constructor() { }

  /**
   * Emits a system alert of type notification.
   */
  public updateAlertNotification(alert: SystemAlertModel) {
    this.alertNotificationSource.next(alert);
  }
}
