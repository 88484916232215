import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { StateService } from 'app/core/services/state.service';

/**
 * Router guard that checks if the route can be activated based on whether there is a client token specified in the query params.
 */
@Injectable()
export class ClientTokenRequiredGuard {
  constructor(private router: Router, private stateService: StateService) {
  }
  /**
   * Determines if the user can activate the route based on a client token being present.
   *
   * @param route
   * @param state
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const clientToken = route.queryParams['clientToken'];
    this.stateService.clientToken = clientToken;

    if (clientToken) {
      return true;
    } else {
      this.router.navigateByUrl('/offline').then(() => false);
    }
  }
}
