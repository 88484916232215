import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
// @ts-ignore
import version from 'raw-loader!../../../../VERSION';
import { filter, map, Subject } from 'rxjs';
import { v1 as uuidv1 } from 'uuid';

/**
 * Service that primarily manages the state of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class StateService implements OnDestroy {

  /**
   * The formatted app version for display.
   */
  public appVersion: string = '';

  /**
   * The original unformatted app version.
   */
  public appVersionRaw: string = '';

  /**
   * The source type used for X-Type in api interceptor.
   */
  public sourceType: string = 'PublicServicesPortal';

  /**
   * The application session id.
   */
  public sessionId: string;

  /**
   * The most recent client token provided for a specified route.
   */
  public clientToken: string = '';

  private supportedLocales = environment.supportedLocales;
  private currentLocale: string = 'en';

  private _destroy$ = new Subject<void>();

  constructor(
    private injector: Injector,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sessionId = uuidv1();
    this.appVersionRaw = version;
    this.appVersion = version ?
      environment.production ? version.split('_')[0] : version :
      '';

    // https://stackoverflow.com/a/60355620
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this._getRootRoute(this.route)),
      filter(r => r.outlet === 'primary'),
    ).subscribe((r) => {
      this.updateLocale(r.snapshot.queryParams.locale);
    });
  }

  /**
   * Sets the current locale for the application.
   *
   * @param locale the locale to be set.
   */
  public setLocale(locale: string): void {
    locale = locale.substring(0, 2);

    if (this.supportedLocales.indexOf(locale) >= 0) {
      this.translateService.use(locale);
      this.currentLocale = locale;
    }
  }

  /**
   * Sets the locale to the locale specified in the route params.
   */
  public async updateLocale(locale?: string): Promise<any> {
    if (locale && this.supportedLocales.includes(locale)) {
      this.setLocale(locale);
    } else {
      this.setLocale('en');
    }
  }

  /**
   * Returns the current locale
   */
  public getLocale(): string {
    return this.currentLocale;
  }

  /**
   * Destroys the service.
   */
  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getRootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
