import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ToastrService } from 'ngx-toastr';

import { SystemAlertModel } from 'app/core/models/ui/system-alert.model';
import { LayoutService } from 'app/core/services/layout.service';

/**
 * App component for the public services portal.
 */
@Component({
  selector: 'bas-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _layoutService: LayoutService,
    private _destroyRef: DestroyRef
  ) { }

  /**
   * Initializes the component
   */
  public ngOnInit(): void {
    this._layoutService.alertNotification$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((a: SystemAlertModel) => {
        this.showAlert(a, 'notification');
      });
  }

  /**
   * Determines the type of alert to display.
   *
   * @param alert the alert information details.
   * @param type the type of alert to display (i.e notification/modal).
   */
  public showAlert(alert: SystemAlertModel, type: 'notification'): void {
    switch (type) {
      case 'notification':
        this.showAlertNotification(alert);
        break;
    }
  }

  /**
   * Displays the alert of type notification.
   *
   * @param alert the alert information details.
   */
  public showAlertNotification(alert: SystemAlertModel): void {
    this._toastr.clear();
    this._toastr.toastrConfig.toastClass = 'toast';
    this._toastr[alert.type](
      alert.message,
      alert.title,
      {
        enableHtml: true,
        timeOut: 15000
      })
      .onTap
      .subscribe(() => {
        if (typeof alert.onTap === 'function') {
          alert.onTap();
        }
      });
  }
}
