// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100vh;
  font-size: 1.8rem;
  text-transform: uppercase;
}
:host i {
  font-size: 7.6rem;
  margin-bottom: 1rem;
}
:host .error-message {
  text-align: center;
  line-height: 1.3;
  margin-bottom: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/offline.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,WAAA;EACA,aAAA;EAEA,iBAAA;EACA,yBAAA;AAAF;AAEE;EACE,iBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;AADJ","sourcesContent":[":host {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-flow: column;\n  width: 100%;\n  height: 100vh;\n\n  font-size: 1.8rem;\n  text-transform: uppercase;\n\n  i {\n    font-size: 7.6rem;\n    margin-bottom: 1rem;\n  }\n\n  .error-message {\n    text-align: center;\n    line-height: 1.3;\n    margin-bottom: 5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
