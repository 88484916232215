export const buildRequestQueryParameter = (filters: any = {}) => {
  const params = {};
  Object.keys(filters).forEach(key => {
    const value = filters[key];
    if (value !== null && value !== undefined && value !== '') {
      params[key] = filters[key];
    }
  });
  return params;
};
