import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * The init component for the public services portal.
 */
@Component({
  selector: 'bas-init',
  template: ''
})
export class InitComponent implements OnInit {
  constructor(private router: Router) { }

  /**
   * Initializes the component.
   */
  public ngOnInit(): void {
    // TODO: figure out the routing for default empty page and error page
    if (true) {
      this.router.navigate(['offline']).then();
    } else {

    }
  }
}
