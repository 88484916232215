import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { ContentPanelComponent } from 'app/core/components/content-panel/content-panel.component';
import { ClientTokenRequiredGuard } from 'app/core/guards/client-token-required.guard';
import { StatusCertificatePaymentGuard } from 'app/core/guards/status-certificate-payment.guard';
import { StripeService } from 'app/core/services/stripe.service';

/**
 * Core module for public services portal application.
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ContentPanelComponent
  ],
  declarations: [
  ],
  exports: [
    TranslateModule,
    ContentPanelComponent
  ],
  providers: [

  ]
})
export class CoreModule {
  /**
   * Provides the services for the core module.
   */
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ClientTokenRequiredGuard,
        StatusCertificatePaymentGuard,
        StripeService
      ]
    };
  }
}
