import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subject } from 'rxjs';

import { ComponentAction, ContentPanelConfig } from 'app/core/components/content-panel/content-panel.model';
import { TranslationsService } from 'app/core/services/translations.service';

/**
 * Component used for content layout
 */
@Component({
  selector: 'bas-content-panel',
  templateUrl: './content-panel.component.html',
  styleUrls: ['./content-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    MatTooltipModule,
    TranslateModule
  ]
})
export class ContentPanelComponent implements OnDestroy {
  @HostBinding('class.is-overflow-hidden') public isOverflowHiddenClass = false;

  @Input() public panelTitle: string;
  @Input() public config: ContentPanelConfig;
  @Input() public backButtonTooltipText: string;

  /**
   * Sets the overflow hidden class value
   */
  @Input() public set overflowHidden(val: boolean) {
    this.isOverflowHiddenClass = val;
  }
  @Input() public subTitlePositionY = '0px';

  @Output() public actionEmit = new EventEmitter<ComponentAction>();

  private _destroy$ = new Subject<void>();

  constructor(public translations: TranslationsService) { }

  /**
   * Destroys the component.
   */
  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Handles the back button click event
   */
  public onBackButtonClick() {
    this.actionEmit.emit({
      name: 'back',
      data: null
    });
  }
}
