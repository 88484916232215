import { DeepPartial } from 'app/core/helpers/deep-partial';
import { TranslationsService } from 'app/core/services/translations.service';

function concatIfExistsPath(path: string, suffix: string): string {
  return path ? `${path}.${suffix}` : suffix;
}

function toTranslationKeysRecursive<T extends object | string>(
  suffix: string,
  objectToTransformOrEndOfPath: T,
  path = ''
): T {
  return typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce(
      (objectToTransform, [key, value]) => {
        objectToTransform[key] = toTranslationKeysRecursive(
          key,
          value,
          concatIfExistsPath(path, suffix)
        );

        return objectToTransform;
      },
      {} as T
    )
    : (concatIfExistsPath(path, suffix) as T);
}

/**
 * Takes the translations and turn them into an object containing the translation keys, allowing it to be used with in the template
 * (ie: translations.root.global.ok | translate).
 *
 * @param translations The raw translations (ie: { "root": { "global": { "ok": "Ok" } } })
 * @returns The same object structure as the translations, but where the translated values are the keys instead
 * (ie: { root: { global: { ok: "root.global.ok" } } })
 */
export const toTranslationKeys = (translations: DeepPartial<TranslationsService>): DeepPartial<TranslationsService> =>
  toTranslationKeysRecursive('', translations);

