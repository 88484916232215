import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiInterceptorSkipHeader, InterceptorSkipHeader } from 'app/core/constants/api.constant';
import { StateService } from 'app/core/services/state.service';

/**
 * Http interceptor for api requests.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private stateService: StateService) {
  }

  /**
   * Implements the HttpInterceptor interface method.
   * Returns an observable of HttpEvent<any>.
   * Handles redirects to api, assets, offline.
   *
   * @param req the http request.
   * @param next the http handler.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.keys().includes(InterceptorSkipHeader)
      || req.headers.keys().includes(ApiInterceptorSkipHeader)
      || req.url.startsWith('/assets')) {

      return next.handle(req).pipe(catchError((error: HttpErrorResponse) => this._handleCatchError(error)));
    } else {
      // only requires the url to be changed when running locally since the api is running on a different port
      // in production/dev the api is running on the same port as the client
      req = req.clone({
        url: `${environment.publicServicesApi}/${req.url}`,
        setHeaders: {
          'X-Type': this.stateService.sourceType,
          'X-Version': this.stateService.appVersionRaw
        }
      });

      return next.handle(req).pipe(catchError((error: HttpErrorResponse) => this._handleCatchError(error)));
    }
  }

  /**
   * Handles any error that are caught from the http request
   */
  private _handleCatchError(error: HttpErrorResponse): Observable<never> {
    return throwError(error);
  }
}
