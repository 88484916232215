import { Injectable } from '@angular/core';

import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { environment } from 'environments/environment';

/**
 * Service that loads stripe via publishable key for injection usage in app.
 */
@Injectable({
  providedIn: 'root'
})
export class StripeService {
  /**
   * Returns the stripe instance.
   */
  public get stripe(): Stripe {
    return this._stripe;
  };

  private _stripe: Stripe = null;

  constructor() {
    // Stripe.js will not be loaded until `loadStripe` is called
    const stripePromise = loadStripe(environment.stripePublishableKey);
    stripePromise.then(stripe => {
      this._stripe = stripe;
    });
  };
}
