import data from 'assets/i18n/en.json';
import statusCertificatesTranslations from 'assets/i18n/status-certificates/en.json';
import * as _ from 'lodash-es';

type Translations = Partial<
  typeof data &
  typeof statusCertificatesTranslations
>;

export const translations: Translations = _.merge({},
  data,
  statusCertificatesTranslations
);
