// This file can be replaced during build by using the `fileReplacements` array.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  development: false,
  local: false,
  publicServicesApi: '',
  supportedLocales: ['en', 'fr'],
  stripePublishableKey: 'pk_test_51KxvhcFxxta1oHe01qhdho8gL6TrWmMcTkwFS2BRP1mxtF6NTptUJgaRs6pxuOnvcQS2y1BAEqOae7o2l5RrFyVQ008TSOtoaR'
};

